import React from 'react';
import Layout from '../components/Layout/Layout';
import Text from '../components/Elements/Text/Text';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';
import Section from '../components/Layout/Section/Section';
import Label from '../components/Elements/Label/Label';
import Headline from '../components/Elements/Headline/Headline';
import {PageTemplateStyled} from '../templates/styles';

const NotFoundPage = () => {
	return (
		<PageTemplateStyled>
			<Meta title={appendSeoTitleSuffix('Fehler 404')} />
			<Layout location={{pathname: '404'}}>
				<Section showPadding>
					<Label text="Fehler 404" />
					<Headline as="h1" text="Seite nicht gefunden" />
					<Section>
						<Text content="Die von dir aufgerufene Seite konnte leider nicht gefunden werden." />
					</Section>
				</Section>
			</Layout>
		</PageTemplateStyled>
	);
};

export default NotFoundPage;
