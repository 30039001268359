import React from 'react';
import PropTypes from 'prop-types';
import {LabelStyled} from './styles';

const Label = props => {
	const {className, text} = props;
	return <LabelStyled className={className} dangerouslySetInnerHTML={{__html: text}} />;
};

Label.propTypes = {
	/** Needed to overwrite styling via styled components. */
	text: PropTypes.string.isRequired,
	className: PropTypes.string
};

Label.defaultProps = {
	className: 'ap-label'
};

/** @component */
export default Label;
