import styled from 'styled-components';
import {orangeBorder} from '../../../styles/utils';

// Used for categories
export const LabelStyled = styled.div`
	position: relative;
	font-weight: 800;
	padding-bottom: 0.8em;
	margin-bottom: 0.8em;
	${orangeBorder()};
`;
